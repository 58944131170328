import axios from "axios";
import moment from "moment";
import "moment/locale/cs";
import {Button} from "primereact/button";
import React, {useCallback, useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import FormComponent from "./components/FormComponent";
import {Row} from "primereact/row";
import {Dialog} from "primereact/dialog";

interface IProps {
    listItems: string[];
    itemsPole: [];
    pole: [];
    item: [];
    data: [];
    message: "";
    duvodNavstevy: [];
    day: string;
    hour: string;
    type: number;
    minute: string;
    displayBasic: boolean;
}

interface IEvevent {
    value: string;
    checked: boolean;
}

export interface IForm {
    name: string;
    surname: string;
    datumForm: string;
    timeForm: string;
    cas: string;
    email: string;
    phone: string;
    datumNarozeni: string;
    message: string;
    childrenForm: string;
    cities: string[];
}

const CalendarPage: React.FC = (IProps) => {
    const [daysData, setDaysData] = useState<[] | null>(null);

    const fetchData = async () => {
        const {data} = await axios.get("data/data.json");
        setDaysData(data);
    };

    const formPrazdnaRezervace = {
        id: null,
        name: "",
        surname: "",
        email: "",
        datumNarozeni: "",
        duvodNavstevy: [],
        phone: "",
        message: "",
        datumForm: "",
        timeForm: "",
        uniqID: "",
        childrenForm: "",
    };

    const [datum, setDatum] = useState(moment());
    const [loading, setLoading] = useState(true);

    const [displayBasic, setDisplayBasic] = useState(false);
    const [nasledujici, setNasledujici] = useState(0);
    const [datumDialog, setDatumDialog] = useState("");
    const [minutaDialog, setMinutaDialog] = useState("");
    const [hourDialog, setHourDialog] = useState("");
    const [formData, setFormData] = useState(formPrazdnaRezervace);
    const [formName, setFormName] = useState("");
    const [formSurname, setFormSurname] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [datumNarozeni, setDatumNarozeni] = useState("");
    const [formPhone, setFormPhone] = useState("");
    const [childrenName, setChildrenName] = useState("");
    const [formMessage, setFormMessage] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [eventsData, setEventData] = useState([]);
    const [potvrzovaciHlaska, setPotvrzovaciHlaska] = useState(false);

    const nasledujiciTyden = useCallback(() => {
        let count = nasledujici + 1;
        setNasledujici(count);
    }, [nasledujici]);

    const predchoziTyden = useCallback(() => {
        let count = nasledujici - 1;
        setNasledujici(count);
    }, [nasledujici]);

    const openDialog = useCallback(
        (datum, hour, minuta) => {
            console.log(datum, hour, minuta);
            setDatumDialog(datum);
            setHourDialog(hour);
            setMinutaDialog(minuta);
            setDisplayBasic(true);
        },
        [datumDialog, minutaDialog]
    );

    const onHide = () => {
        setDisplayBasic(false);
    };

    const onHideHlaska = () => {
        setPotvrzovaciHlaska(false);
    };

    const jeMozneObjednat = useCallback((druh) => {
        return Number(druh) === 0 || Number(druh) === 1 || Number(druh) === 3 || Number(druh) === 5;
    }, []);

    const onFinish = (values: IForm) => {
        if (
            values.name.trim() &&
            values.surname.trim() &&
            values.email.trim() &&
            values.datumNarozeni.trim() &&
            values.phone.trim() &&
            values.datumForm.trim()
        ) {
            values.datumForm = moment(datumDialog).format("Y-MM-DD");
            values.timeForm = hourDialog + ":" + minutaDialog;
            values.cas = hourDialog + ":" + minutaDialog;
            // @ts-ignore
            values.duvodNavstevy = values.cities.toString();

            axios
                .post("https://rezervace.pediaaz.cz/server/api/saveevent", values)
                .then((response) => {
                    axios
                        .all([
                            axios.get("https://rezervace.pediaaz.cz/server/api/event-list"),
                        ])
                        .then((responses: any) => {
                            setEventData(responses[0].data.data);
                        });
                });
            setDisplayBasic(false);
            setPotvrzovaciHlaska(true);
        }
    };

    const naplnPole = useCallback(
        (nazev: string, value: any) => {
            let pole: any = formData;
            pole[`${nazev}`] = value;
            setFormData(pole);
            setFormName(formData.name);
            setFormSurname(formData.surname);
            setDatumNarozeni(formData.datumNarozeni);
            setFormEmail(formData.email);
            setFormPhone(formData.phone);
            setChildrenName(formData.childrenForm);
            setFormMessage(formData.message);
        },
        [formData]
    );

    const potvrzovaciHlaskaFooter = (
        <div>
            <Button label="Zavřít" icon="pi pi-times" onClick={onHideHlaska}/>
        </div>
    );
    const hledejEvent = (datum: string, hours: any, minutes: any) => {
        const hod = Number(hours) < 10 ? "0" + hours : hours;
        let coHeldam = datum + "" + hod + ":" + minutes;
        console.log(coHeldam);
        let vrat = eventsData
            .filter((item: any) => item.kombinace === coHeldam)
            .map((filteredPerson) => {
                return true;
            });
        return vrat[0];
    };

    const jeVetsiDatum = (datum: string, hours: string, minutes: string) => {
        const date = `${datum} ${hours}:${minutes}`;
        console.log("kontrola datumu");

        return (
            moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm") <=
            moment(new Date()).format("YYYY-MM-DD HH:mm")
        );
    };

    const closeTrueClass = (
        druh: number,
        date: string,
        hours: string,
        minutes: string
    ) => {
        if (jeMozneObjednat(druh)) {
            return "closetrue";
        }
        if (jeVetsiDatum(date, hours, minutes)) {
            return "closetrue";
        }

        if (hledejEvent(date, hours, minutes)) {
            return "closetrue";
        }

        return "";
    };

    const checkClikclabe = (
        druh: number,
        date: string,
        hours: string,
        minutes: string
    ) => {
        if (jeMozneObjednat(druh)) {
            return false;
        }
        if (jeVetsiDatum(date, hours, minutes)) {
            return false;
        }

        if (hledejEvent(date, hours, minutes)) {
            return false;
        }

        openDialog(date, hours, minutes);
    };

    useEffect(() => {
        fetchData();
        setDatum(moment().add(nasledujici, "week").startOf("isoWeek"));
    }, [nasledujici]);

    useEffect(() => {
        setLoading(true);
        try {
            axios
                .all([axios.get("https://rezervace.pediaaz.cz/server/api/event-list")])
                .then((responses: any) => {
                    setEventData(responses[0].data.data);
                });
            setLoading(false);
        } catch (e) {
            console.log(e, "error");
            setLoading(false);
        }
    }, []);

    if (!daysData || loading) {
        return (
            <div className="d-flex w-100 justify-content-center h-100 align-content-center align-items-center align-middle">
                <div>Nahrávám...</div>
            </div>
        );
    }

    return (
        <div className="d-flex flex-column w-100 justify-content-center align-items-center">
            <div className="d-flex">
                <div className="d-flex p-3 text-center align-items-center">
                    Týden od &nbsp;{" "}
                    <strong> {moment(datum).format("dddd DD.MM.Y ")}</strong>
                    &nbsp; do
                    &nbsp;
                    <strong>
                        {moment(datum).endOf("isoWeek").format("dddd DD.MM.Y ")}
                    </strong>
                </div>
            </div>
            <div className="d-flex flex-column w-100 ">
                {daysData?.map((dayItems: IProps, day: number) => (
                    <div
                        className="d-flex flex-column flex-md-row "
                        key={`frst-${day}-${dayItems.day}-${Math.random() * 100}-${day}`}
                    >
                        <div
                            className="d-flex  p-2 nadpisDne text-right justify-content-end align-items-end"
                            key={`frst---${dayItems.day}`}
                        >
                            <div className="d-flex">
                                <strong>
                                    {moment(datum).add(day, "days").format("dddd DD.MM.Y ")}
                                </strong>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row flex-wrap">
                            {dayItems.data.map((hodiny: any, hodinyIndex) => (
                                <div
                                    key={`index-calitem-${hodinyIndex}`}
                                    className={`d-flex border border-solid bg-gradient-light calendarItem p-1 text-center align-items-center den${
                                        hodiny.type
                                    } ${closeTrueClass(
                                        hodiny.type,
                                        moment(datum).add(day, "days").format("yyyy-MM-DD"),
                                        hodiny.hour.split(":")[0],
                                        hodiny.hour.split(":")[1]
                                    )}`}
                                    onClick={() =>
                                        checkClikclabe(
                                            hodiny.type,
                                            moment(datum).add(day, "days").format("yyyy-MM-DD"),
                                            hodiny.hour.split(":")[0],
                                            hodiny.hour.split(":")[1]
                                        )
                                    }
                                >
                                    {hodiny.hour}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="d-flex py-3 flex-column text-md-start flex-md-row flex-wrap mb-12  align-content-start justify-content-start pl-5 pt-1 ml-5 legenda">
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den0"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Není možná rezervace
                    </div>
                </div>
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den1"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Přestávka
                    </div>
                </div>
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den2"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Rezervace nemocní
                    </div>
                </div>
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den3"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Administrativní práce (Není možná rezervace)
                    </div>
                </div>
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den4"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Rezervace zdraví
                    </div>
                </div>
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den5"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Poradna pro kojence (Není možná rezervace)
                    </div>
                </div>
                <div className="d-flex">
                    <div className="p-3 ml-5 text-center align-items-center legendaItem den6"></div>
                    <div className="p-1 text-center align-items-center legendText ">
                        Prevence/ Rezervace zdraví
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap mb-12  align-content-start justify-content-start pl-5 pt-1 ml-5 legenda gap-5">
                <div>
                    <Button onClick={predchoziTyden}>Předchozí týden</Button>
                </div>
                <div>
                    <Button onClick={nasledujiciTyden}>Následující týden</Button>
                </div>
            </div>
            <Dialog
                header="Rezervační formulář"
                visible={displayBasic}
                style={{width: "75vw"}}
                onHide={() => onHide()}
                onShow={() => naplnPole("datum", `${datumDialog}`)}
            >
                <Row>
                    <Col>
                        <div className="d-flex">
                            Rezervace na datum:{" "}
                            <strong>
                                {" "}
                                {moment(datumDialog).format("l")} {hourDialog}:{minutaDialog}
                            </strong>
                        </div>
                    </Col>
                </Row>
                <FormComponent
                    datumDialog={datumDialog}
                    hourDialog={hourDialog}
                    minutaDialog={minutaDialog}
                    onHide={onHide}
                    onFinish={onFinish}
                />
            </Dialog>
        </div>
    );
};
export default CalendarPage;
